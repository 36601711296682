//const catalogModule = () => import('./Module.vue');
const products = () => import('./views/products.vue')
const editingProduct = () => import('./views/editingProduct.vue')
const manufacturers = () => import("./views/manufacturers.vue");
const editingManufacturer = () => import("./views/editingManufacturer.vue");
const categories = () => import("./views/categories.vue");
const editingCategory = () => import("./views/editingCategory.vue");
const attributes = () => import("./views/attributes.vue");
const editingAttribute = () => import("./views/editingAttribute.vue");
const moduleRoute = {
    path: '/catalog',
    name: 'catalog',
    children: [
        {
            name: 'products',
            path: 'products',
            component: products,
            meta: {
                title: "conf.manage.products",
                scope: ["products.get"],
            },
        },
        {
            name: "editingProduct",
            path: "products/manage/:id?",
            component: editingProduct,
            props: true,
            meta: {
                title: "conf.products.manage",
                scope: ["products.post", "products.put"],
            },
        },
        {
            name: "categories",
            path: "categories",
            component: categories,
            meta: {
                title: "conf.manage.categories",
                scope: ["categories.get"],
            },
        },
        {
            name: "editingCategory",
            path: "categories/manage/:id?",
            component: editingCategory,
            props: true,
            meta: {
                title: "conf.categories.manage",
                scope: ["categories.post", "categories.put"],
            },
        },
        {
            name: "manufacturers",
            path: "manufacturers",
            component: manufacturers,
            meta: {
                title: "conf.manage.manufacturers",
                scope: ["manufacturers.get"],
            },
        },
        {
            name: "editingManufacturer",
            path: "manufacturers/manage/:id?",
            component: editingManufacturer,
            props: true,
            meta: {
                title: "conf.manufacturers.manage",
                scope: ["manufacturers.post", "manufacturers.put"],
            },
        },
        {
            name: "attributes",
            path: "attributes",
            component: attributes,
            meta: {
                title: "conf.manage.attributes",
                scope: ["predefinedattributes.get"],
            },
        },
        {
            name: "editingAttribute",
            path: "attributes/manage/:id?",
            component: editingAttribute,
            props: true,
            meta: {
                title: "conf.attributes.manage",
                scope: ["predefinedattributes.post", "predefinedattributes.put"],
            },
        },
    ]
};



export default router => {
    router.addRoute(moduleRoute);
};