import { createStore } from "vuex";
import layout from "../theme/store/modules/layout";
import menu from "../theme/store/modules/menu";

const store = createStore({
  state: {
    languages: JSON.parse(localStorage.getItem("sys-languages")),
    currentUser: JSON.parse(localStorage.getItem("currentUser")),
    authToken: localStorage.getItem("authToken"),
    currentLanguage : localStorage.getItem("currentLanguage")
  },
  mutations: {
    updateLanguages(status, languages) {
      status.languages = languages;
      if (languages != null) {
        localStorage.setItem("sys-languages", JSON.stringify(languages));
      } else {
        localStorage.setItem("sys-languages", null);
      }
    },

    updateCurrentUser(status, user) {
      status.currentUser = user;
      if (user != null) {
        localStorage.setItem("currentUser", JSON.stringify(user));
      } else {
        localStorage.setItem("currentUser", null);
      }
    },
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      window.location.reload();
    },
  },
  actions: {
    updateLanguages: (context, languages) => {
      context.commit("updateLanguages", languages);
    },
    updateCurrentUser: (context, user) => {
      context.commit("updateCurrentUser", user);
    },

    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    layout,
    menu,
  },
});

export default store;
