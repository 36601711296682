import { Language } from "@/entities/Language";
import IApiService from "@/services/IApiService";

export default class LanguagesService extends IApiService {
  SERVICE_URL = "languages";

  constructor(component) {
    super(component);
  }

  async get() {
    let languages = await super.get((data) =>
      data.map((i) => {
        return new Language(i);
      })
    );

    if(languages != null && languages.length > 0){
      let firstLanguage = languages.filter(f=> f.id == 1);
      this.$defaultLanguage =firstLanguage.length > 0 ? firstLanguage[0] : 'default-lang';
    }
    
    this.component.$store.dispatch("updateLanguages", languages);
  }
}
