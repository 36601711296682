import { Article } from "@/entities/contents/Article";
import IApiService from "@/services/IApiService";

export default class ArticleService extends IApiService {
  SERVICE_URL = "articles";

  constructor(component) {
    super(component);
  }


  async get() {
    return await super.get((data) => data.map((i) => {
      return new Article(i);
    }));
  }
}
