export class Manufacturer {
  id;
  createdBy;
  createdDate;
  lastModifiedBy;
  lastModifiedDate;
  isDeleted;
  isPublish;
  canEdit;
  metaTitle;
  metaKeywords;
  metaDiscription;
  displayOrder;
  seName;
  name;
  description;
  pictureId;
  pictureUrl;
  picture = null;
  manufacturerLangs = [];
  constructor(data) {
    Object.assign(this, data);
  }
}
