<template>
  <div>
    <Breadcrumbs main="conf.articles" title="conf.articles.manage" />

    <b-overlay :show="isLoading" rounded="sm">
      <DefaultCard :title="`conf.articles.${name}`">
        <template #body>
          <div>
            <b-tabs pills class="tabbed-card">
              <b-tab title="Home" active>
                <template #title> {{ $t(this.$defaultLanguage) }} </template>
                <b-card-text>
                  <b-form class="needs-validation" @submit="submit">
                    <b-row>
                      <b-col cols="8">
                        <div class="row">
                          <div class="col-md-10 mb-3">
                            <label for="name">{{ $t("articles.title") }}</label>
                            <b-form-input
                              type="text"
                              :placeholder="$t('articles.title')"
                              :state="validation.title"
                              v-model="article.title"
                              maxlength="500"
                            ></b-form-input>
                            <b-form-valid-feedback
                              :state="validation.title"
                            ></b-form-valid-feedback>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-10 mb-3">
                            <label for="name">{{ $t("articles.body") }}</label>

                            <QuillEditor
                              :placeholder="$t('articles.body')"
                              v-model:content="article.body"
                              contentType="html"
                              theme="snow"
                              ref="editor"
                            />
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="4">
                        <label for="name">{{ $t("articles.pictureId") }}</label>
                        <FileUpload
                          @callback="submitDataToServer"
                          ref="fileUpload"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>
              </b-tab>

              <b-tab
                title="lang-{{lang.id}}"
                v-for="lang in languages"
                :key="lang.id"
              >
                <template #title> {{ lang.name }} </template>
                <b-card-text>
                  <b-form class="needs-validation">
                    <div class="row">
                      <div class="col-md-10 mb-3">
                        <label for="name">{{ $t("articles.title") }}</label>
                        <b-form-input
                          type="text"
                          :placeholder="$t('articles.title')"
                          :state="validation.title"
                          v-model="getLanguageOfTab(lang).title"
                          maxlength="500"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-10 mb-3">
                        <label for="name">{{ $t("articles.body") }}</label>

                        <QuillEditor
                          :placeholder="$t('articles.body')"
                          v-model:content="getLanguageOfTab(lang).body"
                          contentType="html"
                          theme="snow"
                          :ref="`editor${lang.id}`"
                        />
                      </div>
                    </div>
                  </b-form>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </template>

        <template #footer>
          <b-button
            type="button"
            @click="submit"
            variant="primary"
            class="me-2"
            >{{ $t("save") }}</b-button
          >
        </template>
      </DefaultCard>
    </b-overlay>
  </div>
</template>
<script>
import Breadcrumbs from "@/theme/components/bread_crumbs";
import { Article } from "@/entities/contents/Article";
import { ArticleLang } from "@/entities/langs/contents/ArticleLang";
import ArticleService from "../services/ArticleService";

export default {
  name: "ArticlesManage",
  components: { Breadcrumbs },
  props: ["name"],
  data: () => ({
    isLoading: false,
    api: null,
    article: new Article({
      isPublish: 1,
      canEdit: 1,
      articleLangs: [],
      pictureId: null,
    }),
    languages: [],
    validation: {
      name: null,
      file: null,
    },
  }),
  mounted() {
    this.api = new ArticleService(this);
    this.languages = (this.$store.state.languages ?? []).filter(
      (f) => f.id != 1
    );

    this.getData();
  },
  methods: {
    changePublish() {
      this.article.isPublish = this.article.isPublish == 1 ? 0 : 1;
    },

    submit() {
      this.validation.title =
        this.article.title != null && this.article.title != "";

      if (this.validation.title) {
        this.article.articleLangs = this.article.articleLangs.filter(
          (f) => f.title != null && f.title != ""
        );

        this.$refs.fileUpload.uploadFile();
      }
    },

    submitDataToServer(picture) {
      const { article, api } = this;

      if (picture != null) {
        this.article.pictureId = picture.id;
      } else {
        this.article.pictureId = null;
      }

      this.isLoading = true;

      const callbackSuccess = () => {
        this.isLoading = false;
      };

      const callbackError = (error) => {
        console.error(error);
        this.isLoading = false;
      };

      api.update(article, callbackSuccess, callbackError);
    },

    async getData() {
      const callbackSuccess = (data) => {
        this.article = new Article(data);
        this.$refs.fileUpload.setPicture(data.picture);

        this.$refs.editor.editor.querySelector(".ql-editor").innerHTML =
          data.body;

        this.article.articleLangs.forEach((e) => {
          this.$refs[`editor${e.languageId}`][0].editor.querySelector(
            ".ql-editor"
          ).innerHTML = this.getLanguageOfTab({
            id: e.languageId,
          }).body;
        });
      };

      if (this.name != null) {
        await this.api.getById(this.name, callbackSuccess);
      }
    },

    getLanguageOfTab(lang) {
      this.article.articleLangs = this.article.articleLangs || [];
      let articleLangs = this.article.articleLangs.filter(
        (f) => f.languageId === lang.id
      );
      return articleLangs.length
        ? articleLangs[0]
        : this.article.articleLangs.push(
            new ArticleLang({ languageId: lang.id })
          ) && this.article.articleLangs[this.article.articleLangs.length - 1];
    },
  },
};
</script>
