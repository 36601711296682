<template>
  <div>
    print_table {{ title  }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
  },
}
</script>

<style>

</style>