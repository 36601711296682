import articles from './views/articles.vue';
import faqs from './views/faqs.vue';
import slideShows from './views/slideShows.vue';

const moduleRoute = {
    path: '/contents',
    name: 'contents',
    children: [
        {
            name: "articles",
            path: "articles/:name",
            component: articles,
            props: true,
            meta: {
                title: "conf.manage.articles",
                scope: ["articles.get"],
            },
        },
        {
            name: "faqs",
            path: "faqs",
            component: faqs,
            meta: {
                title: "conf.manage.faqs",
                scope: ["faqs.get"],
            },
        },
        {
            name: "slideshows",
            path: "slideshows",
            component: slideShows,
            meta: {
                title: "conf.manage.slideshows",
                scope: ["slideshows.get"],
            },
        }
    ],
};



export default router => {
    router.addRoute(moduleRoute);
};