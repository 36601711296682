export class Category {
  id;
  createdDate;
  lastModifiedBy;
  lastModifiedDate;
  isDeleted;
  isPublish;
  canEdit;
  name;
  fullCategoryName;
  description;
  parentCategoryId;
  picture = null;
  pictureUrl = null;
  children = [] | null;
  categoryLangs = [] | null;

  constructor(data) {
    Object.assign(this, data);
  }
}
