export class Language {
  id;
  createdDate;
  lastModifiedBy;
  lastModifiedDate;
  isDeleted;
  isPublish;
  canEdit;
  name;
  shortcut;
  isDefault;

  constructor(data) {
    Object.assign(this, data);
  }
}
