import { Product } from "@/entities/Product";
import IApiService from "@/services/IApiService";

export default class ProductService extends IApiService {
  SERVICE_URL = "products";

  constructor(component) {
    super(component);
  }

  async get() {
    return await super.get((data) =>
      data.map((i) => {
        return new Product(i);
      })
    );
  }

  async search(data) {
    return await super.search(data, (data) =>
      data.map((i) => {
        return new Product(i);
      })
    );
  }

  async updateFiles(file, data, success, failed) {
    let formData = new FormData();

    file.forEach((e) => {
      formData.append(`files`, e.file);
      formData.append(`id`, e.id);
    });

    formData.append("productId", data.id);

    return await this.html
      .post(`productimages/upload`, formData)
      .then((rs) => {
        return success != null ? success(rs.data) : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed != null ? failed(response) : null;
      });
  }

  async deletePrice(id, success, failed) {
    return await this.html
      .delete(`${this.SERVICE_URL}/deleteprice/${id}`)
      .then(() => {
        return success ? success() : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed ? failed(response) : null;
      });
  }

  async deleteIngredient(id, success, failed) {
    return await this.html
      .delete(`${this.SERVICE_URL}/deleteingredient/${id}`)
      .then(() => {
        return success ? success() : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed ? failed(response) : null;
      });
  }

  async deleteImage(id, success, failed) {
    return await this.html
      .delete(`${this.SERVICE_URL}/deleteimage/${id}`)
      .then(() => {
        return success ? success() : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed ? failed(response) : null;
      });
  }

  async deleteAttribute(id, success, failed) {
    return await this.html
      .delete(`${this.SERVICE_URL}/deleteattribute/${id}`)
      .then(() => {
        return success ? success() : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed ? failed(response) : null;
      });
  }


  async deleteAttributeValue(id, success, failed) {
    return await this.html
      .delete(`${this.SERVICE_URL}/deleteattributevalue/${id}`)
      .then(() => {
        return success ? success() : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed ? failed(response) : null;
      });
  }
}
