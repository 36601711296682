const users = () => import("./views/users.vue");
const editingUser = () => import("./views/editingUser.vue");
const editProfile = () => import("./views/editProfile.vue");
const editSystem = () => import("./views/editSystem.vue");
const integrationPlugin = () => import("./views/integrationPlugin.vue");
const apisApp = () => import("./views/apisApps.vue");

const moduleRoute = {
  path: "/settings",
  name: "settings",
  children: [
    {
      name: "users",
      path: "users",
      component: users,
      meta: {
        title: "conf.manage.users",
        scope: ["users.get"],
      },
    },
    {
      name: "editingUser",
      path: "users/manage/:id?",
      component: editingUser,
      props: true,
      meta: {
        title: "conf.users.manage",
        scope: ["users.post", "users.put"],
      },
    },
    {
      name: "editProfile",
      path: "users/profile",
      component: editProfile,
      meta: {
        title: "conf.profile.manage",
        scope: ["profile.put"],
      },
    },
    {
      name: "system-settings",
      path: "system-settings",
      component: editSystem,
      meta: {
        title: "conf.settings.system.manage",
        scope: ["systemsettings.get","systemsettings.put"],
      },
    },
    {
      name: "integration-plugins",
      path: "integration-plugins",
      component: integrationPlugin,
      meta: {
        title: "conf.settings.integration.manage",
        scope: ["integrationplugins.get"],
      },
    },
    {
      name: "apis-apps",
      path: "apis-apps",
      component: apisApp,
      meta: {
        title: "conf.settings.apis.app.manage",
        scope: ["apisapps.get"],
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};


