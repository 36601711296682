const customers = () => import("./views/customers.vue");
const editingCustomer = () => import("./views/editingCustomer.vue");

const moduleRoute = {
  path: "/customer",
  name: "customer",
  children: [
    {
      name: "customers",
      path: "customers",
      component: customers,
      meta: {
        title: "conf.manage.customers",
        scope: ["customers.get"],
      },
    },
    {
      name: "editingCustomer",
      path: "customers/manage/:id?",
      component: editingCustomer,
      props: true,
      meta: {
        title: "conf.customers.manage",
        scope: ["customers.post", "customers.put"],
      },
    },

    {
      name: "suppliers",
      path: "suppliers",
      component: customers,
      meta: {
        title: "conf.manage.customers",
        scope: ["customers.get"],
      },
    },
    {
      name: "editingSupplier",
      path: "suppliers/manage/:id?",
      component: editingCustomer,
      props: true,
      meta: {
        title: "conf.customers.manage",
        scope: ["customers.post", "customers.put"],
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
