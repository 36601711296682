<template>
    <div class="header-logo-wrapper">
        <div class="logo-wrapper">
            <router-link to="/">
                <img class="img-fluid" src="../../assets/images/logo/logo.png" style="height: 40px;" alt />
            </router-link>
        </div>
        <div class="toggle-sidebar" @click="toggle_sidebar">
            <vue-feather class="status_toggle middle sidebar-toggle"
                     type="align-center"
                     id="sidebar-toggle"></vue-feather>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderLogo',
        methods: {
            toggle_sidebar() {
                this.$store.dispatch('menu/opensidebar');
            },
        },
    };
</script>

<style scoped="">
img.img-fluid{

    max-height: 40px !important;
}
</style>
