
const invoices = () => import('./views/invoices.vue')
const editingInvoice = () => import('./views/editingInvoice.vue')
const realtimeInvoice = () => import('./views/realtimeInvoice.vue')

const moduleRoute = {
    path: '/bills',
    name: 'bills',
    children: [
        {
            name: 'sales',
            path: 'sales',
            component: invoices,
            meta: {
                title: "conf.manage.sales",
                scope: ["invoices.get"],
            },
        },
        {
            name: "editingSales",
            path: "sales/manage/:id?",
            component: editingInvoice,
            props: true,
            meta: {
                title: "conf.invoices.manage",
                scope: ["invoices.post", "invoices.put"],
            },
        },
        {
            name: "realtimeSales",
            path: "sales/realtime/",
            component: realtimeInvoice,
            meta: {
                title: "conf.invoices.manage.realtime",
                scope: ["invoices.get", "invoices.post", "invoices.put"],
            },
        }
    ]
};



export default router => {
    router.addRoute(moduleRoute);
};