export class AppUser {
  id;
  createdBy;
  createdDate;
  lastModifiedBy;
  lastModifiedDate;
  isDeleted;
  isPublish;
  canEdit;
  fullName;
  mobile;
  email;
  userName;
  password;
  resetCode;
  resetCodeExpired;
  scopes;
  stores = [] | null;
  scopesList = [] | null;
  token = null;
  type;
  branchId;
  constructor(data) {
    Object.assign(this, data);
  }
}
