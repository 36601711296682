const kitchens = () => import('./views/kitchens.vue');
const menuCategories = () => import('./views/menuCategories.vue');
const foodMenus = () => import('./views/foodMenus.vue');
const restaurantStructures = () => import('./views/restaurantStructures.vue');
const moduleRoute = {
    path: '/restaurant',
    name: 'restaurant',
    children: [
        {
            name: "kitchen",
            path: "kitchens",
            component: kitchens,
            meta: {
                title: "conf.manage.kitchens",
                scope: ["kitchens.get"],
            },
        },
        {
            name: "menu-categories",
            path: "menu-categories",
            component: menuCategories,
            meta: {
                title: "conf.manage.menucategories",
                scope: ["menucategories.get"],
            },
        },
        {
            name: "food-menu",
            path: "food-menu",
            component: foodMenus,
            meta: {
                title: "conf.manage.foodsmenus",
                scope: ["foodsmenus.get"],
            },
        },
        {
            name: "restaurant-structures",
            path: "restaurant-structures",
            component: restaurantStructures,
            meta: {
                title: "conf.manage.restaurantstructures",
                scope: ["restaurantstructures.get"],
            },
        }
    ],
};



export default router => {
    router.addRoute(moduleRoute);
};