import { AppUser } from "@/entities/AppUser";
import IApiService from "@/services/IApiService";

export default class ProfileService extends IApiService {
  SERVICE_URL = "profile";

  constructor(component) {
    super(component);
  }

  async get() {
    var user = await super.get((data) => new AppUser(data));
    if (user != null) {
      this.component.$store.dispatch("updateCurrentUser", user);

      if (user.token)
        localStorage.setItem("authToken", user.token);
    }

    return user;
  }
}
