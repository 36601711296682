export class Faq {
    id
    createdDate
    lastModifiedBy
    lastModifiedDate
    isDeleted
    isPublish
    canEdit
    question = ''
    answer = ''
    faqLangs = []

    constructor(data) {
        Object.assign(this, data)
    }

}