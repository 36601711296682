const discounts = () => import("./views/discounts.vue");
const editingDiscount = () => import("./views/editingDiscount.vue");
const moduleRoute = {
  path: "/promotions",
  name: "promotions",
  children: [
    {
      name: "discounts",
      path: "discounts",
      component: discounts,
      meta: {
        title: "conf.manage.discounts",
        scope: ["discounts.get"],
      },
    },
    {
      name: "editingDiscount",
      path: "discounts/manage/:id?",
      component: editingDiscount,
      props: true,
      meta: {
        title: "conf.discounts.manage",
        scope: ["discounts.post", "discounts.put"],
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
