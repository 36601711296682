export class SlideShow {
    id
    createdDate
    lastModifiedBy
    lastModifiedDate
    isDeleted
    isPublish
    canEdit
    title = ''
    description = ''
    url = ''
    pictureId
    productId
    categoryId
    manufacturerId
    picture
    slideShowLangs = []


    constructor(data) {
        Object.assign(this, data)
    }
 
}