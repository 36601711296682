export class Article {
    id
    createdDate
    lastModifiedBy
    lastModifiedDate
    isDeleted
    isPublish
    canEdit
    articleName = ''
    title = ''
    body = ''
    pictureId
    picture
    articleLangs = []

    constructor(data) {
        Object.assign(this, data)
    }

    validate() {
        if (!this.title) return 'error.title.required'
        return true
    }
}