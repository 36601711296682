import moment from "moment";
import emitter from "@/functions/emitter.js";
import { createApp, h, nextTick } from "vue";
import PrintTable from "@/pages/components/print_table.vue";

export const registerGlobals = (app) => {
  app.config.globalProperties.eventBus = emitter;

  /**
   * @params {date} date to be converted to timeago
   * @returns returns timeAgo
   */
  app.config.globalProperties.$dates = {
    format(date, format = "yyyy-MM-DD") {
      return moment(date).format(format);
    },
  };

  app.config.globalProperties.$utils = {
    debounce(func, wait) {
      let timeout;
      return function () {
        const context = this,
          args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait ?? 1000);
      };
    },
    print(component, header, data) {
      const app = createApp({
        components: { PrintTable },
        render() {
          return h(PrintTable, {
            header: header,
            data: data,
          });
        },
      });

      let html;
      app.mount(document.createElement("div"));
      nextTick().then(async () => {
        html = app._container;
        await component.$htmlToPaper(html.innerHTML);
      });
    }
  };

  app.config.globalProperties.$defaultLanguage = "default-lang";

  String.prototype.toCamelCase = function () {
    let STR = this.toLowerCase()
      .trim()
      .split(/[ -_]/g)
      .map((word) => word.replace(word[0], word[0].toString().toUpperCase()))
      .join("");
    return STR.replace(STR[0], STR[0].toLowerCase());
  };

  String.prototype.validateEmail = function () {
    let pattern = new RegExp(
      "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"
    );
    return pattern.test(this);
  };

  //decode the geometry string to get the coordinates
  String.prototype.decodeGeometry = function () {
    if (!this) return [];
    let geometry = this;
    let coordinates = [];
    let index = 0;
    let lat = 0;
    let lng = 0;
    while (index < geometry.length) {
      let b;
      let shift = 0;
      let result = 0;
      do {
        b = geometry.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      let dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;
      shift = 0;
      result = 0;
      do {
        b = geometry.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      let dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;
      coordinates.push({ lat: lat * 1e-5, lng: lng * 1e-5 });
    }
    return coordinates;
  };

  app.config.globalProperties.$access = {
    check(component, scope) {
      let currentUser = component.$store.state.currentUser;
      console.log(currentUser, scope, currentUser.scopesList.includes(scope));
      if (currentUser != null) {
        return (
          currentUser.scopesList != null &&
          currentUser.scopesList.includes(scope)
        );
      }
      return true;
    },
  };

  app.config.globalProperties.$alert = {
    confirm(component, callback) {
      component
        .$swal({
          text: component.$t("alert.delete.row"),
          showCancelButton: true,
          confirmButtonText: component.$t("yes"),
          confirmButtonColor: "#4466f2",
          cancelButtonText: component.$t("no"),
          cancelButtonColor: "#efefef",
          reverseButtons: true,
          icon: "error",
        })
        .then(async (rs) => {
          if (rs.isConfirmed) {
            callback();
          }
        });
    },
  };

  (app.config.globalProperties.$constValue = (key, constant) => {
    var values = constant.filter((f) => f.value == key);
    return values.length > 0 ? values[0] : { text: "" };
  }),
    (app.config.globalProperties.$ColorValue = (key) => {
      var values = [
        { value: 0, color: "badge badge-danger" },
        { value: 1, color: "badge badge-primary" },
        { value: 2, color: "badge badge-secondary" },
        { value: 3, color: "badge badge-success" },
        { value: 4, color: "badge badge-info" },
        { value: 5, color: "badge badge-warning" },
      ].filter((f) => f.value == key);
      return values.length > 0 ? values[0].color : "";
    }),
    (app.config.globalProperties.$ColorVariant = (key) => {
      var values = [
        { value: 0, color: "light" },
        { value: 1, color: "primary" },
        { value: 2, color: "secondary" },
        { value: 3, color: "success" },
        { value: 4, color: "info" },
        { value: 5, color: "warning" },
      ].filter((f) => f.value == key);
      return values.length > 0 ? values[0].color : "";
    }),
    (app.config.globalProperties.$constants = {
      YesNo(component) {
        return [
          { value: 1, text: component.$t("yes") },
          { value: 2, text: component.$t("no") },
        ];
      },

      YesNoBool(component) {
        return [
          { value: true, text: component.$t("yes") },
          { value: false, text: component.$t("no") },
        ];
      },

      AssetTypes(component) {
        return [
          { value: '', text: component.$t("constant.assettypes"), disabled: true },
          { value: 1, text: component.$t("constant.assettypes.asset") },
          { value: 2, text: component.$t("constant.assettypes.notasset") },
        ];
      },
      CustomerTypes(component) {
        return [
          { value: '', text: component.$t("constant.customertypes"), disabled: true },
          { value: 1, text: component.$t("constant.customertypes.customer") },
          { value: 2, text: component.$t("constant.customertypes.supplier") },
          { value: 3, text: component.$t("constant.customertypes.employee") },
          { value: 4, text: component.$t("constant.customertypes.company") },
          {
            value: 5,
            text: component.$t("constant.customertypes.organization"),
          },
          { value: 6, text: component.$t("constant.customertypes.owner") },
        ];
      },

      DeliveryDates(component) {

        return [
          { value: '', text: component.$t("constant.deliverydates"), disabled: true },
          { value: 0, text: component.$t("constant.deliverydates.none") },
          {
            value: 1,
            text: component.$t("constant.deliverydates.onetotwodays"),
          },
          {
            value: 2,
            text: component.$t("constant.deliverydates.threetosevendays"),
          },
          {
            value: 3,
            text: component.$t("constant.deliverydates.onetotwoweeks"),
          },
          {
            value: 4,
            text: component.$t("constant.deliverydates.onetotwomonths"),
          },
        ];
      },

      DepreciationStatus(component) {
        return [
          { value: '', text: component.$t("constant.depreciationstatus"), disabled: true },
          { value: 1, text: component.$t("constant.depreciationstatus.no") },
          { value: 2, text: component.$t("constant.depreciationstatus.yes") },
        ];
      },

      DepreciationWays(component) {
        return [
          { value: '', text: component.$t("constant.depreciationways"), disabled: true },
          {
            value: 1,
            text: component.$t("constant.depreciationways.fixedquantity"),
          },
          {
            value: 2,
            text: component.$t("constant.depreciationways.productionquantity"),
          },
          {
            value: 3,
            text: component.$t("constant.depreciationways.decliningquantity"),
          },
          {
            value: 4,
            text: component.$t("constant.depreciationways.yearsquantity"),
          },
        ];
      },

      InvoiceTypes(component) {
        return [
          { value: '', text: component.$t("constant.invoicetypes"), disabled: true },
          { value: 1, text: component.$t("constant.invoicetypes.sales") },
          { value: 2, text: component.$t("constant.invoicetypes.purchase") },
          { value: 3, text: component.$t("constant.invoicetypes.returnsales") },
          {
            value: 4,
            text: component.$t("constant.invoicetypes.returnpurchase"),
          },
          { value: 5, text: component.$t("constant.invoicetypes.salesoffer") },
          {
            value: 6,
            text: component.$t("constant.invoicetypes.purchaseoffer"),
          },
        ];
      },

      ProductGroupTypes(component) {
        return [
          { value: '', text: component.$t("constant.productgrouptypes"), disabled: true },
          { value: 1, text: component.$t("constant.productgrouptypes.simple") },
          { value: 2, text: component.$t("constant.productgrouptypes.group") },
        ];
      },

      ProductTypes(component) {
        return [
          { value: '', text: component.$t("constant.producttypes"), disabled: true },
          { value: 1, text: component.$t("constant.producttypes.product") },
          { value: 2, text: component.$t("constant.producttypes.service") },
        ];
      },

      StoreEveluationWays(component) {
        return [
          { value: '', text: component.$t("constant.storeeveluationways"), disabled: true },
          { value: 1, text: component.$t("constant.storeeveluationways.avg") },
          { value: 2, text: component.$t("constant.storeeveluationways.fifo") },
        ];
      },
      DiscountTypes(component) {
        return [
          { value: '', text: component.$t("constant.discounttypes"), disabled: true },
          { value: 1, text: component.$t("constant.discounttypes.orders") },
          { value: 2, text: component.$t("constant.discounttypes.products") },
          { value: 3, text: component.$t("constant.discounttypes.categories") },
          {
            value: 4,
            text: component.$t("constant.discounttypes.manufacturers"),
          },
          { value: 5, text: component.$t("constant.discounttypes.customers") },
        ];
      },

      DiscountLimitation(component) {
        return [
          { value: '', text: component.$t("constant.discountlimitation"), disabled: true },
          {
            value: 1,
            text: component.$t("constant.discountlimitation.unlimited"),
          },
          {
            value: 2,
            text: component.$t("constant.discountlimitation.ntimeonly"),
          },
          {
            value: 3,
            text: component.$t("constant.discountlimitation.ntimepercustomer"),
          },
        ];
      },

      DiscountPercentage(component) {
        return [
          { value: '', text: component.$t("constant.discountpercentage"), disabled: true },
          { value: 1, text: component.$t("constant.discountpercentage.yes") },
          { value: 0, text: component.$t("constant.discountpercentage.no") },
        ];
      },

      ValueTypes(component) {
        return [
          { value: '', text: component.$t("constant.valuetypes"), disabled: true },
          { value: 1, text: component.$t("constant.valuetypes.fixed") },
          { value: 2, text: component.$t("constant.valuetypes.parcentage") },
        ];
      },

      UserTypes(component) {
        return [
          { value: '', text: component.$t("constant.usertypes"), disabled: true },
          { value: 1, text: component.$t("constant.usertypes.admin") },
          { value: 2, text: component.$t("constant.usertypes.cashier") },
          { value: 3, text: component.$t("constant.usertypes.driver") },
        ];
      },

      InvoiceStatus(component) {
        return [
          { value: '', text: component.$t("constant.invoicestatus"), disabled: true },
          { value: 1, text: component.$t("constant.invoicestatus.pending") },
          { value: 2, text: component.$t("constant.invoicestatus.active") },
          { value: 3, text: component.$t("constant.invoicestatus.declined") },
          { value: 4, text: component.$t("constant.invoicestatus.closed") },
        ];
      },
      DeliveryStatus(component) {
        return [
          { value: '', text: component.$t("constant.deliverystatus"), disabled: true },
          { value: 0, text: component.$t("constant.deliverystatus.none") },
          { value: 1, text: component.$t("constant.deliverystatus.kitchen") },
          { value: 2, text: component.$t("constant.deliverystatus.ready") },
          { value: 3, text: component.$t("constant.deliverystatus.assigned") },
          { value: 4, text: component.$t("constant.deliverystatus.routing") },
          { value: 5, text: component.$t("constant.deliverystatus.delivered") },
          { value: 6, text: component.$t("constant.deliverystatus.closed") },
        ];
      },
      InvoiceSource(component) {
        return [
          { value: '', text: component.$t("constant.invoicesource"), disabled: true },
          { value: 1, text: component.$t("constant.invoicesource.cashier") },
          { value: 2, text: component.$t("constant.invoicesource.api") },
          { value: 3, text: component.$t("constant.invoicesource.callcenter") },
        ];
      },
      InvoicePlaces(component) {
        return [
          { value: '', text: component.$t("constant.invoiceplaces"), disabled: true },
          { value: 1, text: component.$t("constant.invoiceplaces.internal") },
          { value: 2, text: component.$t("constant.invoiceplaces.external") },
          { value: 3, text: component.$t("constant.invoiceplaces.delivery") },
        ];
      },
    });
};


export const printHtmlOptions = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=no',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};
