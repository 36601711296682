<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-xl-7 b-center bg-size"
        :style="{
          backgroundImage:
            'url(' + require('@/theme/assets/images/login/2.jpg') + ')',
        }"
        style="
          background-size: cover;
          background-position: center center;
          display: block;
        "
      >
        <img
          class="bg-img-cover bg-center"
          src="@/theme/assets/images/login/2.jpg"
          alt="looginpage"
          style="display: none"
        />
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div>
              <a class="logo text-left">
                <img
                  class="img-fluid for-light"
                  src="@/theme/assets/images/logo/login.png"
                  alt="looginpage"
                />
                <img
                  class="img-fluid for-dark"
                  src="@/theme/assets/images/logo/logo_dark.png"
                  alt="looginpage"
                />
              </a>
            </div>
            <div class="login-main login-form-card login-res">
              <form class="theme-form" @submit.prevent="onSubmit()">
                <h4>{{ $t("login.sign.account") }}</h4>
                <p>{{ $t("login.enter.username") }}</p>
                <div class="form-group">
                  <label class="col-form-label">{{ $t("gl.username") }}</label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="form.username"
                    required=""
                    :placeholder="$t('gl.username')"
                  />
                </div>
                <div class="form-group">
                  <label class="col-form-label">{{ $t("gl.password") }}</label>
                  <input
                    class="form-control"
                    :type="passwordInput"
                    v-model="form.password"
                    required=""
                    placeholder="*********"
                  />
                  <div
                    class="show-hide"
                    @click="
                      passwordInput =
                        passwordInput == 'text' ? 'password' : 'text'
                    "
                  >
                    <span class="show"> </span>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-block" type="submit">
                    {{ $t("login.signin") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import ProfileService from "@/services/ProfileService";

export default {
  data() {
    return {
      api: null, 
      passwordInput: "password",
      form: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    this.api = new AuthService(this);
   
  },
  methods: {
    async getUserData() {
      var instance = this;
      var user = await new ProfileService(this).get();
      if(user != null) {
        instance.$router.push("/");
      }
    },
    async onSubmit() {
      var instance = this;
      await instance.api.post(instance.form, function (data) {
        if (data != null) {
          localStorage.setItem("authToken", data);
          instance.getUserData();
          //instance.$router.push("/");
        }
      });
    },
  },
};
</script>

<style></style>
