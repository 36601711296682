import { Category } from "@/entities/Category";
import IApiService from "@/services/IApiService";

export default class CategoryService extends IApiService {
  SERVICE_URL = "categories";

  constructor(component) {
    super(component);
  }


  async get() {
    return await super.get((data) => data.map((i) => {
      return new Category(i);
    }));
  }
}
