import { createRouter, createWebHistory } from "vue-router";
import login from "@/pages/login_page.vue";
const routes = [
  {
    path: "/",
    component: () => import("../pages/main_page.vue"),
    name: "dashboard",
    meta: {
      title: "dashboard",
    },
  },
  {
    path: "/auth/login",
    name: "login",
    component: login,
    meta: {
      title: "page.login",
    },
  },
];

const router = createRouter({
  history: createWebHistory('admin'),
 
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let authToken = localStorage.getItem("authToken");

  const path = ["/auth/login", "/auth/register"];
  if (path.includes(to.path) || authToken) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      
      if (
        to.meta.scope != null &&
       !to.meta.scope.some((v) => currentUser.scopesList.includes(v)) 
      ) {
        console.log("You don't have permission to access this page !");
        return next("/");
      }
    }

    return next();
  }
  next("/auth/login");
});

export default router;
