import { Faq } from "@/entities/contents/Faq";
import IApiService from "@/services/IApiService";

export default class FaqService extends IApiService {
  SERVICE_URL = "faqs";

  constructor(component) {
    super(component);
  }


  async get() {
    return await super.get((data) => data.map((i) => {
      return new Faq(i);
    }));
  }
}
