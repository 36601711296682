const storeMovements = () => import('./views/storeMovements.vue');

const moduleRoute = {
    path: '/store-movements',
    name: 'store-movements',
    children: [
        {
            name: "movements",
            path: "movements",
            component: storeMovements,
            meta: {
                title: "conf.manage.storeMovements",
                scope: ["storemovements.get"],
            },
        },
        
    ],
};



export default router => {
    router.addRoute(moduleRoute);
};