
import IApiService from "@/services/IApiService";

export default class AuthService extends IApiService {
  SERVICE_URL = "auth";

  constructor(component) {
    super(component);
  }

  async post(data, success, failed) {
    return await this.html
      .post(this.SERVICE_URL, data)
      .then((rs) => {
        return success != null ? success(rs.data) : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed != null ? failed(response) : null;
      });
  }

}
