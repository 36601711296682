export class ArticleLang {
    id
    title = ''
    body = ''
    articleId
    languageId

    constructor(data) {
        Object.assign(this, data)
    }


}