<template>
  <li class="profile-nav onhover-dropdown p-0 mr-0">
    <div class="media profile-media">
      <img
        class="b-r-10"
        src="../../assets/images/dashboard/profile.jpg"
        alt=""
      />
      <div class="media-body">
        <span>{{ currentUser.fullName }}</span>
        <p class="mb-0 font-roboto">
          {{ currentUser.userName }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="{ name: 'editProfile' }"
          ><vue-feather type="user"></vue-feather><span> {{$t('account')}} </span>
        </router-link>
      </li>
      <li>
        <a href="#"
          ><vue-feather type="mail"></vue-feather><span>Inbox</span></a
        >
      </li>
      <li>
        <a href="#"
          ><vue-feather type="file-text"></vue-feather><span>Taskboard</span></a
        >
      </li>
      <li>
        <a href="#"
          ><vue-feather type="settings"></vue-feather><span>Settings</span></a
        >
      </li>
      <li>
        <a @click="logout"
          ><vue-feather type="log-in"></vue-feather><span>{{$t('log_out')}}</span></a
        >
      </li>
    </ul>
  </li>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderProfile",
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
    }),
  },
  methods: {
    logout: function () {
      localStorage.removeItem("authToken");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("sys-languages");

      this.$store.dispatch("updateCurrentUser", null);

      this.$router.push({ name: "login" });
    },
  },
};
</script>
