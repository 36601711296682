<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header" v-if="title">
            <div class="media">
              <h5>{{ $t(title) }}</h5>
              <div class="media-body text-right">
                <slot name="actions"></slot>
              </div>
            </div>
          </div>
          <div class="card-body">
            <slot name="body"></slot>
          </div>

          <div class="card-footer" v-if="!!this.$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultCard",
  props: {
    title: {
      default: "",
    },
  },
};
</script>

<style></style>
