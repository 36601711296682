import { Picture } from "@/entities/Picture";
import IApiService from "@/services/IApiService";

export default class PictureService extends IApiService {
  SERVICE_URL = "pictures";

  constructor(component) {
    super(component, "multipart/form-data");
  }

  async get() {
    var user = await super.get((data) => new Picture(data));
    if (user != null) {
      this.component.$store.dispatch("updateCurrentUser", user);
    }

    return user;
  }

  async uploadFile(file, success, failed) {
    let formData = new FormData();
    formData.append("files", file);

    return await this.html
      .post(`${this.SERVICE_URL}`, formData)
      .then((rs) => {
        return success != null ? success(rs.data) : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed != null ? failed(response) : null;
      });
  }


  async updateFile(file,data, success, failed) {
    let formData = new FormData();
    formData.append("files", file);
    formData.append("id", data.id);

    return await this.html
      .put(`${this.SERVICE_URL}`, formData)
      .then((rs) => {
        return success != null ? success(rs.data) : null;
      })
      .catch(({ response }) => {
        if (response != null && response.data != null) {
          this.component.$toast.error(this.getErrorMessage(response));
        } else {
          this.component.$toast.error(
            `<p><strong>${this.component.$t(
              "alert"
            )}</strong><br>${this.component.$t("error.general.network")}</p>`
          );
        }
        return failed != null ? failed(response) : null;
      });
  }
}
