<template>
  <div :class="cstyle">
    <div class="custom-file b-form-file is-invalid">
      <label
        data-browse="Browse"
        class="custom-file-label"
        :for="`file_upload__BVID__${guid}`"
      >
        <img :src="imageFile" v-if="imageFile" />
        <div v-if="!imageFile" class="empty-image"></div>
        <input
          type="file"
          class="custom-file-input is-invalid"
          :id="`file_upload__BVID__${guid}`"
          style="z-index: -5"
          @change="selectFile(item, $event)"
        /><span
          class="d-block form-file-text btn btn-success"
          style="pointer-events: none"
          >{{ $t("select_file") }}</span
        ></label
      >
    </div>
  </div>
</template>

<script>
import PictureService from "@/services/PictureService";
export default {
  name: "FileUpload",
  props: {
    cstyle: {
      default: "",
    },
    cfile: {
      default: null,
    },
    cImage: {
      default: null,
    },
  },
  data() {
    return {
      api: null,
      imageFile: null,
      picture: null,
      file: null,
      guid: null,
    };
  },

  computed: {
    fileData: {
      get() {
        return this.cfile;
      },
      set(value) {
        this.$emit("update:cfile", value);
      },
    },
  },
  async mounted() {
    this.api = new PictureService(this);
    this.guid = this.guidNumber();
    this.imageFile = this.cImage;
  },
  methods: {
    guidNumber() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },

    setPicture(picture) {
      this.picture = picture;
      if (this.picture != null) {
        this.imageFile = this.picture.url;
      } else {
        this.imageFile = null;
      }
    },

    selectFile(i, e) {
      this.images = [];
      let fileList = Array.prototype.slice.call(e.target.files);
      fileList.forEach((f) => {
        if (!f.type.match("image.*")) {
          return;
        }

        let reader = new FileReader();
        let instance = this;

        reader.onload = function (e) {
          instance.imageFile = e.target.result;
        };
        reader.readAsDataURL(f);

        instance.file = f;
        instance.fileData = f;
      });
    },

    async uploadFile() {
      var instance = this;
      console.log(this.picture,this.file);
      if (instance.file) {
        if (this.picture == null) {
          await instance.api.uploadFile(
            instance.file,
            function (data) {
              instance.$emit("callback", data);
            },
            function () {}
          );
        } else {
          await instance.api.updateFile(
            instance.file,
            instance.picture,
            function (data) {
              instance.$emit("callback", data);
            },
            function () {}
          );
        }
      } else {
        instance.$emit("callback", null);
      }
    },
  },
};
</script>
<style scoped>
.custom-file {
  text-align: center;
}

.custom-file-input {
  opacity: 0;
}

.custom-file-label {
  width: 100%;
}

input {
  width: 0px;
}
.empty-image,
img {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.inline .custom-file-label {
  display: flex;
  justify-content: flex-start;
  align-content: space-around;
  align-items: center;
}
.inline .empty-image,
.inline img {
  height: 60px;
  width: 60px;
  display: inline-block;
  margin-inline-end: 5px;
  margin-bottom: 0px;
}
</style>
