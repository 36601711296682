<template>
  <div>
    <Breadcrumbs main="dashboard" :title="`conf.slideShows`" />

    <b-overlay :show="editingLoading" rounded="sm">
      <DefaultCard title="conf.manage.slideShows">
        <template #body>
          <div>
            <b-form class="needs-validation" @submit="submit">
              <b-row class="mb-3">
                <b-col md="10">
                  <label>{{ $t("slideShows.title") }}</label>
                  <b-form-input
                    type="text"
                    :placeholder="$t('slideShows.title')"
                    :state="validation.title"
                    v-model="slideShow.title"
                    maxlength="500"
                  />
                </b-col>
                <b-col md="10">
                  <label>{{ $t("slideShows.description") }}</label>
                  <b-form-textarea
                    type="text"
                    :placeholder="$t('slideShows.description')"
                    v-model="slideShow.description"
                    maxlength="1000"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <!-- slide url types -->
                <b-col md="5">
                  <label>{{ $t("slideShows.slideUrlType") }}</label>
                  <select
                    class="form-control"
                    v-model="slideUrlType"
                    @change="slideUrlTypeChange"
                  >
                    <option
                      v-for="slideUrlType in slideUrlTypes"
                      :key="slideUrlType.value"
                      :value="slideUrlType.value"
                    >
                      {{ $t(slideUrlType.text) }}
                    </option>
                  </select>
                </b-col>

                <b-col md="5" v-if="slideUrlType == 1">
                  <label>{{ $t("slideShows.url") }}</label>
                  <b-form-input
                    type="text"
                    :placeholder="$t('slideShows.url')"
                    v-model="slideShow.url"
                    maxlength="500"
                  />
                </b-col>

                <!-- menu category -->

                <b-col md="5" v-if="slideUrlType == 2">
                  <label>{{ $t("slideShows.categoryId") }}</label>
                  <b-form-select
                    v-model="slideShow.categoryId"
                    :options="categories"
                    :placeholder="$t('slideShows.categoryId')"
                  />
                </b-col>

                <!-- kitchen -->

                <b-col md="5" v-if="slideUrlType == 3">
                  <label>{{ $t("slideShows.manufacturerId") }}</label>
                  <b-form-select
                    v-model="slideShow.manufacturerId"
                    :options="manufacturers"
                    :placeholder="$t('slideShows.manufacturerId')"
                  />
                </b-col>

                <!-- basic product   -->

                <b-col md="5" v-if="slideUrlType == 4">
                  <label>{{ $t("slideShows.productId") }}</label>
                  <MultiSelect
                    v-model="slideShow.productId"
                    :options="products"
                    track-by="value"
                    label="text"
                    :close-on-select="true"
                    :showLabels="false"
                    :placeholder="$t('slideShows.productId')"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="10">
                  <label for="name">{{ $t("slideShows.pictureId") }}</label>
                  <FileUpload @callback="submitDataToServer" ref="fileUpload" />
                </b-col>
              </b-row>
            </b-form>
          </div>
        </template>

        <template #footer>
          <b-button
            type="button"
            @click="submit"
            variant="primary"
            class="me-2"
            >{{ $t("save") }}</b-button
          >
          <b-button type="button" @click="resetForm" variant="light">{{
            $t("reset")
          }}</b-button>
        </template>
      </DefaultCard>
    </b-overlay>

    <DefaultCard>
      <template #body>
        <div class="table-responsive datatable-vue m-0 vue-smart">
          <b-overlay :show="isLoading" rounded="sm">
            <VTable
              :data="slideShows"
              :page-size="30"
              v-model:currentPage="currentPage"
              @totalPagesChanged="totalPages = $event"
              class="table"
            >
              <template #head>
                <th>#</th>
                <th style="max-width: 50px"></th>
                <VTh sortKey="name">{{ $t("slideShows.title") }}</VTh>
                <VTh sortKey="isPublish">{{ $t("ispublish") }}</VTh>
                <VTh sortKey="lastModifiedDate">{{
                  $t("last_modified_date")
                }}</VTh>
                <th>{{ $t("modified_by") }}</th>
                <th style="max-width: 115px"></th>
              </template>
              <template #body="{ rows }">
                <tr v-for="(row, index) in rows" :key="row.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      :src="row.picture.url"
                      v-if="row.picture"
                      class="max70"
                    />
                  </td>
                  <td>{{ row.title }}</td>

                  <td>
                    <div class="media-body text-start switch-sm">
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="row.isPublish == 1"
                          :disabled="
                            !(
                              row.canEdit == 1 &&
                              $access.check(this, 'foodsmenus.put')
                            )
                          "
                          @change="changePublish(row)"
                        /><span
                          :class="
                            row.isPublish == 1
                              ? 'switch-state bg-success'
                              : 'switch-state bg-danger'
                          "
                        ></span>
                      </label>
                    </div>
                  </td>
                  <td>{{ this.$dates.format(row.lastModifiedDate) }}</td>
                  <td>{{ row.lastModifiedBy }}</td>
                  <td>
                    <b-button
                      variant="outline-secondary"
                      size="xs"
                      v-if="
                        row.canEdit == 1 &&
                        $access.check(this, 'foodsmenus.delete')
                      "
                      class="btn-square btn-outline-secondary me-2"
                      @click="deleteRow(row.id)"
                    >
                      <vue-feather type="trash" size="20"></vue-feather>
                    </b-button>

                    <button
                      v-show="$access.check(this, 'foodsmenus.put')"
                      @click="editRow(row)"
                      class="btn btn-outline-primary btn-xs btn-square btn-outline-primary me-2"
                    >
                      <vue-feather type="edit-3" size="20"></vue-feather>
                    </button>
                  </td>
                </tr>
              </template>
            </VTable>
            <div>
              <VTPagination
                v-model:currentPage="currentPage"
                :total-pages="totalPages"
                :boundary-links="true"
                class="smart-pagination"
              />
            </div>
          </b-overlay></div
      ></template>
    </DefaultCard>
  </div>
</template>

<script>
import Breadcrumbs from "@/theme/components/bread_crumbs";
import SlideShowService from "../services/SlideShowService";
import ProductService from "@/modules/catalog/services/ProductService";
import CategoryService from "@/modules/catalog/services/CategoryService";
import ManufacturerService from "@/modules/catalog/services/ManufacturerService";
import { SlideShow } from "@/entities/contents/SlideShow";

export default {
  name: "ConfigurationSlideShows",
  components: {
    Breadcrumbs,
  },
  data: () => ({
    isLoading: true,
    editingLoading: false,
    slideShows: [],
    categories: [],
    manufacturers: [],
    products: [],
    slideShow: new SlideShow({ isPublish: 1, canEdit: 1 }),
    api: null,
    slideUrlType: 1,
    slideUrlTypes: [
      { value: 1, text: "slideShows.url" },
      { value: 2, text: "slideShows.categoryId" },
      { value: 3, text: "slideShows.manufacturerId" },
      { value: 4, text: "slideShows.productId" },
    ],
    currentPage: 1,
    totalPages: 0,
    validation: {
      name: null,
    },
  }),
  async mounted() {
    this.api = new SlideShowService(this);

    this.products = ((await new ProductService(this).get()) ?? []).map((m) => ({
      value: m.id,
      text: m.name,
    }));

    this.categories = ((await new CategoryService(this).get()) ?? []).map(
      (m) => ({
        value: m.id,
        text: m.name,
      })
    );

    this.manufacturers = (
      (await new ManufacturerService(this).get()) ?? []
    ).map((m) => ({
      value: m.id,
      text: m.name,
    }));

    await this.fetchSlideShows();
  },
  methods: {
    //Fetch slideShows from server
    async fetchSlideShows() {
      this.isLoading = true;
      this.slideShows = (await this.api.get()) ?? [];
      this.isLoading = false;

      //sort the slideShows desc by id
      this.slideShows.sort((a, b) => b.id - a.id);
    },

    //get product by id
    getProductById(id) {
      id = typeof id === "object" && id != null ? id.value : id;
      return this.products.find((m) => m.value === id);
    },

    submit() {
      var instance = this;

      instance.validation.title =
        instance.slideShow.title != null && instance.slideShow.title != "";

      if (!instance.validation.title) return;

      instance.editingLoading = true;

      if (this.slideShow.productId)
        this.slideShow.productId =
          this.slideShow.productId.value ?? this.slideShow.productId;

      //set the category id , manufacturer id or product id to null if the slideUrlType is not equal to 2,3 or 4
      if (this.slideUrlType != 2) this.slideShow.categoryId = null;
      if (this.slideUrlType != 3) this.slideShow.manufacturerId = null;
      if (this.slideUrlType != 4) this.slideShow.productId = null;

      this.$refs.fileUpload.uploadFile();
    },

    submitDataToServer(picture) {
      const { slideShow, api } = this;

      if (picture != null) {
        slideShow.pictureId = picture.id;
      }

      this.editingLoading = true;

      const callbackSuccess = () => {
        this.editingLoading = false;
        this.resetForm();
      };

      const callbackError = (error) => {
        console.error(error);
        this.editingLoading = false;
        this.resetForm();
      };

      if (this.slideShow.id) {
        api.update(slideShow, callbackSuccess, callbackError);
      } else {
        api.post(slideShow, callbackSuccess, callbackError);
      }
    },

    resetForm() {
      this.editingLoading = false;
      this.slideShow = new SlideShow({ isPublish: 1, canEdit: 1 });
      this.validation.title = null;
      this.slideUrlType = 1;
      this.$refs.fileUpload.setPicture(null);
      this.fetchSlideShows();
    },

    changePublish(row) {
      row.isPublish = row.isPublish === 1 ? 0 : 1;

      const callbackSuccess = () => (this.isLoading = false);
      const callbackError = () => (this.isLoading = false);

      this.api.updatePublish(
        row.id,
        row.isPublish,
        callbackSuccess,
        callbackError
      );
    },

    editRow(row) {
      this.slideShow = row;

      this.slideShow.productId = this.products.find(
        (m) => m.value == row.productId
      );

      this.$refs.fileUpload.setPicture(this.slideShow.picture);

      //update slide url type
      this.slideUrlType = this.slideShow.url
        ? 1
        : this.slideShow.categoryId
        ? 2
        : this.slideShow.manufacturerId
        ? 3
        : this.slideShow.productId
        ? 4
        : 1;

      window.scrollTo(0, 0);
    },

    async deleteRow(id) {
      const callbackSuccess = () => {
        this.isLoading = false;
        this.slideShows = this.slideShows.filter((f) => f.id !== id);
      };

      const callbackError = () => {
        this.isLoading = false;
      };

      this.$alert.confirm(this, async () => {
        this.isLoading = true;
        await this.api.delete(id, callbackSuccess, callbackError);
      });
    },
  },
};
</script>

<style>
.multiselect__placeholder {
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
