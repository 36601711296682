<template>
  <div>
    <Breadcrumbs main="dashboard" :title="`conf.faqs`" />

    <b-overlay :show="editingLoading" rounded="sm">
      <DefaultCard title="conf.manage.faqs">
        <template #body>
          <div>
            <b-tabs pills class="tabbed-card" v-model="tabActive">
              <b-tab title="Home" active>
                <template #title> {{ $t(this.$defaultLanguage) }} </template>
                <b-card-text>
                  <b-form class="needs-validation" @submit="submit">
                    <b-row class="mb-3">
                      <b-col md="10">
                        <label>{{ $t("faqs.question") }}</label>
                        <b-form-input
                          type="text"
                          :placeholder="$t('faqs.question')"
                          :state="validation.question"
                          v-model="faq.question"
                          maxlength="1000"
                        />
                      </b-col>
                    </b-row>

                    <b-row class="mb-3">
                      <b-col md="10">
                        <label>{{ $t("faqs.answer") }}</label>

                        <QuillEditor
                          :placeholder="$t('faqs.answer')"
                          v-model:content="faq.answer"
                          contentType="html"
                          theme="snow"
                          ref="editor"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>
              </b-tab>

              <b-tab
                title="lang-{{lang.id}}"
                v-for="lang in languages"
                :key="lang.id"
              >
                <template #title> {{ lang.name }} </template>
                <b-card-text>
                  <b-form class="needs-validation">
                    <div class="row">
                      <div class="col-md-4 mb-3">
                        <label>{{ $t("faqs.question") }}</label>
                        <b-form-input
                          type="text"
                          :placeholder="$t('faqs.question')"
                          v-model="getLanguageOfTab(lang).question"
                          maxlength="1000"
                        />
                      </div>
                    </div>

                    <b-row class="mb-3">
                      <b-col md="10">
                        <label>{{ $t("faqs.answer") }}</label>

                        <QuillEditor
                          :placeholder="$t('faqs.answer')"
                          v-model:content="getLanguageOfTab(lang).answer"
                          contentType="html"
                          theme="snow"
                          :ref="`editor${lang.id}`"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </template>

        <template #footer>
          <b-button
            type="button"
            @click="submit"
            variant="primary"
            class="me-2"
            >{{ $t("save") }}</b-button
          >
          <b-button type="button" @click="resetForm" variant="light">{{
            $t("reset")
          }}</b-button>
        </template>
      </DefaultCard>
    </b-overlay>

    <DefaultCard>
      <template #body>
        <div class="table-responsive datatable-vue m-0 vue-smart">
          <b-overlay :show="isLoading" rounded="sm">
            <VTable
              :data="faqs"
              :page-size="30"
              v-model:currentPage="currentPage"
              @totalPagesChanged="totalPages = $event"
              class="table"
            >
              <template #head>
                <th>#</th>
                <VTh sortKey="question">{{ $t("faqs.question") }}</VTh>
                <VTh sortKey="isPublish">{{ $t("ispublish") }}</VTh>
                <VTh sortKey="lastModifiedDate">{{
                  $t("last_modified_date")
                }}</VTh>
                <th>{{ $t("modified_by") }}</th>
                <th style="max-width: 115px"></th>
              </template>
              <template #body="{ rows }">
                <tr v-for="(row, index) in rows" :key="row.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.question }}</td>
                  <td>
                    <div class="media-body text-start switch-sm">
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="row.isPublish == 1"
                          :disabled="
                            !(
                              row.canEdit == 1 &&
                              $access.check(this, 'faqs.put')
                            )
                          "
                          @change="changePublish(row)"
                        /><span
                          :class="
                            row.isPublish == 1
                              ? 'switch-state bg-success'
                              : 'switch-state bg-danger'
                          "
                        ></span>
                      </label>
                    </div>
                  </td>
                  <td>{{ this.$dates.format(row.lastModifiedDate) }}</td>
                  <td>{{ row.lastModifiedBy }}</td>
                  <td>
                    <b-button
                      variant="outline-secondary"
                      size="xs"
                      v-if="
                        row.canEdit == 1 && $access.check(this, 'faqs.delete')
                      "
                      class="btn-square btn-outline-secondary me-2"
                      @click="deleteRow(row.id)"
                    >
                      <vue-feather type="trash" size="20"></vue-feather>
                    </b-button>

                    <button
                      v-show="$access.check(this, 'faqs.put')"
                      @click="editRow(row)"
                      class="btn btn-outline-primary btn-xs btn-square btn-outline-primary me-2"
                    >
                      <vue-feather type="edit-3" size="20"></vue-feather>
                    </button>
                  </td>
                </tr>
              </template>
            </VTable>
            <div>
              <VTPagination
                v-model:currentPage="currentPage"
                :total-pages="totalPages"
                :boundary-links="true"
                class="smart-pagination"
              />
            </div>
          </b-overlay></div
      ></template>
    </DefaultCard>
  </div>
</template>

<script>
import Breadcrumbs from "@/theme/components/bread_crumbs";
import FaqService from "../services/FaqService";
import { Faq } from "@/entities/contents/Faq";
import { FaqLang } from "@/entities/langs/contents/FaqLang";

export default {
  name: "ConfigurationFaqs",
  components: {
    Breadcrumbs,
  },
  data: () => ({
    isLoading: true,
    editingLoading: false,
    faqs: [],
    languages: [],
    faq: new Faq({ isPublish: 1, canEdit: 1 }),
    api: null,
    currentPage: 1,
    totalPages: 0,
    tabActive: 0,
    validation: {
      question: null,
    },
  }),
  async mounted() {
    this.api = new FaqService(this);

    this.languages = (this.$store.state.languages ?? []).filter(
      (f) => f.id != 1
    );

    await this.fetchFaqs();
  },
  methods: {
    //Fetch faqs from server
    async fetchFaqs() {
      this.isLoading = true;
      this.faqs = (await this.api.get()) ?? [];
      this.isLoading = false;

      //sort the faqs desc by id
      this.faqs.sort((a, b) => b.id - a.id);
    },
    submit() {
      const { api, faq, validation } = this;

      validation.question = faq.question != null && faq.question != "";

      if (!validation.question) {
        return;
      }

      this.editingLoading = true;

      const callback = (successCallback, errorCallback) => () => {
        this.editingLoading = false;
        if (successCallback && typeof successCallback === "function") {
          successCallback();
        }
        if (errorCallback && typeof errorCallback === "function") {
          errorCallback();
        }
      };
      if (faq.id) {
        api.update(faq, callback(this.resetForm), callback());
      } else {
        api.post(faq, callback(this.resetForm), callback());
      }
    },
    resetForm() {
      this.editingLoading = false;
      this.faq = new Faq({ isPublish: 1, canEdit: 1 });
      this.validation.question = null;
      this.fetchFaqs();

      this.tabActive = 0;

      this.$refs.editor.editor.querySelector(".ql-editor").innerHTML = "";
    },

    changePublish(row) {
      row.isPublish = row.isPublish === 1 ? 0 : 1;

      const callbackSuccess = () => (this.isLoading = false);
      const callbackError = () => (this.isLoading = false);

      this.api.updatePublish(
        row.id,
        row.isPublish,
        callbackSuccess,
        callbackError
      );
    },

    editRow(row) {
      this.faq = row;

      this.$refs.editor.editor.querySelector(".ql-editor").innerHTML =
        this.faq.answer;

      this.faq.faqLangs.forEach((e) => {
        this.$refs[`editor${e.languageId}`][0].editor.querySelector(
          ".ql-editor"
        ).innerHTML = this.getLanguageOfTab({
          id: e.languageId,
        }).answer;
      });


      this.tabActive = 0;
      window.scrollTo(0, 0);
    },

    getLanguageOfTab(lang) {
      this.faq.faqLangs = this.faq.faqLangs || [];
      let faqLangs = this.faq.faqLangs.filter((f) => f.languageId === lang.id);
      return faqLangs.length
        ? faqLangs[0]
        : this.faq.faqLangs.push(new FaqLang({ languageId: lang.id })) &&
            this.faq.faqLangs[this.faq.faqLangs.length - 1];
    },

    async deleteRow(id) {
      const callbackSuccess = () => {
        this.isLoading = false;
        this.faqs = this.faqs.filter((f) => f.id !== id);
      };

      const callbackError = () => {
        this.isLoading = false;
      };

      this.$alert.confirm(this, async () => {
        this.isLoading = true;
        await this.api.delete(id, callbackSuccess, callbackError);
      });
    },
  },
};
</script>
