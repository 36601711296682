export class Product {
  id;
  createdBy;
  createdDate;
  lastModifiedBy;
  lastModifiedDate;
  isDeleted;
  isPublish;
  canEdit;
  metaTitle;
  metaKeywords;
  metaDiscription;
  displayOrder;
  seName;
  productType;
  productGroupType;
  isOnline;
  productUnitId;
  productUnitIdName;
  name;
  description;
  fullDescription;
  categoryId;
  categoryIdName;
  manufacturerId;
  manufacturerIdName;
  defualtStore;
  barcode;
  gtin;
  sku;
  price;
  cost;
  taxCategoryId;
  additionalShippingCharge;
  weight;
  length;
  width;
  height;
  deliveryDate;
  isAsset;
  eveluationWay;
  expiredDate;
  depreciationWay;
  depreciationRate;
  productAge;
  depreciationAccountId;

  productIngredients = [];
  productStoresOptions = [];
  productTierPrices = [];
  productLangs = [];
  productAttributes = [];
  productImages = [];
  category;
  manufacturer;
  productUnit;

  constructor(data) {
    Object.assign(this, data);
    if (!data) {
      this.canEdit = 1;
      this.isPublish = 1;
      this.fullDescription = "-";
      return;
    }
  }
}
