import { SlideShow } from "@/entities/contents/SlideShow";
import IApiService from "@/services/IApiService";

export default class SlideShowService extends IApiService {
  SERVICE_URL = "slideshows";

  constructor(component) {
    super(component);
  }


  async get() {
    return await super.get((data) => data.map((i) => {
      return new SlideShow(i);
    }));
  }
}
