export class Picture {
  id;
  createdBy;
  createdDate;
  lastModifiedBy;
  lastModifiedDate;
  isDeleted;
  isPublish;
  canEdit;
  name;
  url;
  categories = [] | null;
  manufacturers = [] | null;
  products = [] | null;

  constructor(data) {
    Object.assign(this, data);
  }
}
