export class FaqLang {
    id
    question = ''
    answer = ''
    faqId
    languageId

    constructor(data) {
        Object.assign(this, data)
    }


}