import { Manufacturer } from "@/entities/Manufacturer";
import IApiService from "@/services/IApiService";

export default class ManufacturerService extends IApiService {
  SERVICE_URL = "manufacturers";

  constructor(component) {
    super(component);
  }


  async get() {
    return await super.get((data) => data.map((i) => {
      return new Manufacturer(i);
    }));
  }
}
