<template>
  <li class="mega-menu outside">
    <a
      class="nav-link p-1"
      :class="{ active: openbonusUI }"
      href="javascript:void(0);"
      @click="openmegamenu"
    >
      <vue-feather type="layers"></vue-feather
      ><span>{{ $t("shortcuts") }}</span>
    </a>
    <div
      class="mega-menu-container nav-submenu menu-to-be-close"
      :class="{ 'd-block': openbonusUI }"
    >
      <div class="container-fluid">
        <div class="row">
          <div
            class="col mega-box"
            v-for="(menuItem, index) in megamenuItems"
            :key="index"
          >
            <div class="mobile-title d-none" v-if="index == 0">
              <h5>Mega menu</h5>
              <vue-feather type="x" @click="closeBonusUI"></vue-feather>
            </div>
            <div
              class="link-section"
              :class="[menuItem.design, { active: menuItem.active }]"
            >
              <div>
                <h6 @click="setNavActive(menuItem, index)">
                  {{ $t(menuItem.title) }}
                </h6>
              </div>
              <ul :class="{ open: menuItem.active }">
                <li
                  v-for="(childrenItem, index) in menuItem.children"
                  :key="index"
                >
                  <router-link
                    :to="{ name: childrenItem.path }"
                    @click="this.openbonusUI = false"
                  >
                    <vue-feather
                      :type="childrenItem.icon"
                      v-if="childrenItem.icon"
                    ></vue-feather>
                    {{ $t(childrenItem.title) }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderBonusUI",
  data() {
    return {
      openbonusUI: false,
      megamenuItems: [],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
      megamenuItemsStored: (state) => state.menu.megamenu,
    }),
  },
  mounted() {
    this.megamenuItems = this.megamenuItemsStored
      .map((f) => {
        f.children =
          f.children != null
            ? f.children.filter((c) =>
                this.currentUser.scopesList.includes(c.scope)
              )
            : null;

        return f;
      })
      .filter((f) => f.children == null || f.children.length > 0);
  },
  methods: {
    openmegamenu() {
      this.openbonusUI = !this.openbonusUI;
    },
    closeBonusUI() {
      this.openbonusUI = false;
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setBonusNavActive", item);
    },
  },
};
</script>
